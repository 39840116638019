import PropTypes from "prop-types"
import React from "react"

import {
  Chip,
  Container,
  Typography,
  Box,
} from "@material-ui/core"

const Header = ({ chip, headline }) => (
  <>
    <Container 
      maxWidth={false}
      disableGutters
    >
      <Container
        component="header"
        maxWidth="lg"
      >
        <Box 
          paddingY={2}
          color="grey.700"
        >
          <Typography
            variant="body1"
          >
            BillsHappenReviews.com
          </Typography>
        </Box>
      </Container>
    </Container>
    <Container 
      
      maxWidth={false}
      disableGutters
    >
      <Box
        paddingY={12}
        bgcolor="primary.main"
        color="primary.contrastText"
      >
        <Container maxWidth="md">
            <Chip
              label={chip}
              color="secondary"
            />
            <Typography 
              variant="h1"
            >
              {headline}
            </Typography>
        </Container>
      </Box>
      
    </Container>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
