/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"


import {
  Box,
  Container,
  createMuiTheme,
  CssBaseline,
  ThemeProvider,
  Typography,
} from "@material-ui/core"

import createBreakpoints from '@material-ui/core/styles/createBreakpoints'


const Layout = ({ topic, subject, children }) => {

  const breakpoints = createBreakpoints({})
  
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#09a8e2',
        contrastText: '#FFF',
      },
      secondary: {
        main: '#f02a07',
        contrastText: '#FFF',
      },
      background: {
        default: '#FFF',
      },
    },
    typography: {
      fontSize: 16,
      h1: {
        fontWeight: 600,
        fontSize: "2.85em",
        lineHeight: 1.167,
        letterSpacing: 0,
        [breakpoints.up('sm')]: {
          fontSize: "4rem",
        },
      },
      h2: {
        fontWeight: 600,
        fontSize: "2.125em",
        lineHeight: 1.167,
        letterSpacing: 0,
        [breakpoints.up('sm')]: {
          fontSize: "3rem",
        },
      },
      h3: {
        fontWeight: 600,
        fontSize: "1.5em",
        lineHeight: 1.167,
        letterSpacing: 0,
        [breakpoints.up('sm')]: {
          fontSize: "2.5rem",
        },
      },
    }
  });

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth={false} disableGutters>
        <Header 
          headline={subject}
          chip={topic}
        />
        <Container component="main" maxWidth="md">
          {children}
        </Container>
        <Container component="footer" maxWidth="lg">
          <Box 
            paddingTop={4} 
            color="grey.700"
          />
          <Typography variant="body2" align="center">
            © {new Date().getFullYear()} {data.site.siteMetadata.title}
          </Typography>
        </Container>
      </Container>
    </ThemeProvider>
    
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
